
export const ossEmoji = (res) => {
    // if (process.env.NODE_ENV == "development") {
    //     return 'https://yezai.oss-cn-shenzhen.aliyuncs.com/dev/emoji/' + res
    // } else if (process.env.NODE_ENV == "production") {
    //     // instance.defaults.baseURL = 'http://192.168.100.113:8081';
    //     return 'https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/prod/emoji/' + res
    //     // return 'https://img.yezai.love/' + res
    // }
    return 'https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/' + res 
}