<template>
  <div>
    <Header />
    <div class="box">
      <div class="wrapper">
        <div class="back" @click="back">
          <i class="el-icon-arrow-left"></i>
          返回消息列表
        </div>
        <div class="info">
          <div class="left">
            <div class="header">
              <img :src="avatar || src" alt="" />
            </div>
            <div class="info-i">
              <p>{{ nickName }}</p>
              <p class="p_id">ID: {{ id }}</p>
              <p>
                <span v-if="age">{{ age + "岁" }}</span>
                <span v-if="city">&nbsp;|&nbsp;{{ city }}</span>
                <span v-if="height">&nbsp;|&nbsp;{{ height + "cm" }}</span>
                <span v-if="education">&nbsp;|&nbsp;{{ education }}</span>
                <!-- 有房 | {{ education }} -->
              </p>
            </div>
          </div>
          <div class="right">
            <div v-if="iSstatus">
              <div class="Btn1" v-if="falg" @click="guanzhu">{{ gztext }}</div>
              <div class="Btn1-a" v-else @click="guanzhu">{{ gztext }}</div>
            </div>

            <div class="Btn2" @click="report">举报</div>

            <div v-if="iSstatus">
              <div class="Btn3" v-if="fl" @click="pingbi">{{ pbtext }}</div>
              <div class="Btn3-a" v-else @click="pingbi">{{ pbtext }}</div>
            </div>
          </div>
        </div>
        <div class="line"></div>

        <div class="chat" v-if="iSstatus">
          <!-- <div class="chat_re"></div> -->
          <!-- <div class="jiazaiX" @click="jiazai">加载消息...</div> -->
          <!-- 聊天框 -->
          <ul>
            <li
              v-for="item in chatMessage"
              :key="item.id"
              :class="item.from == id ? 'atalk' : 'btalk'"
            >
              <div
                :class="item.from == id ? 'chat-i' : 'chat-ii'"
                v-if="item.from == id"
              >
                <img
                  :src="item.from == id ? `${avatar}` : `${userAvatar}`"
                  alt=""
                />
                <div
                  class="chat-mess"
                  :class="item.from == id ? 'chat-mess' : 'chat-mess-i'"
                >
                  <div class="chat-mess-i-time">{{ item.time }}</div>

                  <div class="mes_private" v-if="reg.test(item.data)">
                    <div class="mes_private_no" v-if="iSmes">
                      <div class="private_no_left">
                        该消息可能包含联系方式，请务必警惕，避免发生钱财损失
                      </div>
                      <div class="private_no_right" @click="openKnowledge">
                        <img
                          src="../../../assets/images/tubiao/tongxinsuox.png"
                          mode=""
                        />
                        点击解锁
                      </div>
                    </div>
                    <!-- <div class="mes_private_yes" v-else >
                      {{ item.data }}
                    </div> -->
                    <div
                      class="mes_private_yes"
                      v-else
                      v-html="renderTxt(item.data)"
                    />
                    <!-- {{ item.data }}
                    </div> -->
                  </div>
                  <!-- <div class="mes" v-else>{{ item.data }}</div> -->
                  <div class="mes" v-else v-html="renderTxt(item.data)" />
                </div>
              </div>

              <div :class="item.from == id ? 'chat-i' : 'chat-ii'" v-else>
                <div
                  class="chat-mess"
                  :class="item.from == id ? 'chat-mess' : 'chat-mess-i'"
                >
                  <div class="chat-mess-i-time">{{ item.time }}</div>
                  <div class="mes" v-html="renderTxt(item.data)" />
                </div>
                <img
                  :src="item.from == id ? `${avatar}` : `${userAvatar}`"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <!-- 提示警告 -->
          <div class="chat-Tips">
            <div class="tips-box">
              <!-- <h3 style="color: #333; font-size: 18px">
                <img src="../../../assets/images/tubiao/chataq.png" alt="" />
                安全中心公告
              </h3> -->
              <div class="tip-aq">
                <img
                  class="tip-aq_img"
                  src="../../../assets/images/tubiao/chataq.png"
                  alt=""
                />
                <p>安全中心公告</p>
              </div>
              <p style="line-hight: 25px; color: #333333; font-size: 14px">
                防诈骗提醒：在婚恋交友过程中，谨慎与他人交换联系方式，任何以“赌博网站漏洞”、“时时彩”、“六合彩”、“内部投资渠道”等名义，引诱你与TA发生钱财往来的行为都极有可能是诈骗，请务必警惕，以免遭受钱财损失。
              </p>
              <p
                style="color: #fd686e; cursor: pointer; font-size: 14px"
                @click="an"
              >
                点击查看“杀猪盘”等最新骗术>>
              </p>
            </div>
          </div>
          <!-- 消息内容 -->

          <div class="message">
            <!-- <ChatEmoji
              v-on:selectEmoji="selectEmoji"
              :inpMessage="notedata"
            /> -->
            <div class="mess" :value="notedata">
              <!--  -->
              <textarea
                placeholder="说点什么吧……"
                @keydown="handleKeyCode($event)"
                v-model="notedata"
                ref="txtDom"
              ></textarea>
            </div>
          </div>
          <!-- 发送消息 -->
          <div class="Btn">
            <div class="Btn-box" @click="sends">免费发信</div>
          </div>
          <!-- 安全提示 -->
          <div class="safe-tips">
            <div class="safe-tips-iii">
              <img src="../../../assets/images/tubiao/tishi.png" alt="" />
              <span class="title">安全征婚提示：</span>
            </div>
            在以下情况下请不要轻易透露你的联系方式（如：电话、手机号码、邮箱、即时通信、通信地址等）
            <br />
            1.在未充分了解对方前，请不要轻易透露你的联系方式。
            <br />
            2.当对方无相片或资料填写不完整时，请不要轻易透露你的联系方式。
          </div>
        </div>
        <div class="chat-error" v-else>
          <!-- 提示话语 -->
          <div class="info-b">
            <img
              src="../.../../../../assets/images/info/infoAbnormal.png"
              alt=""
            />

            <p>
              温馨提示：该用户（{{
                id
              }}）账号异常或存在不诚信行为，为了你的征婚安全，该账号已被限制，如果你的权益被侵犯,
              <span @click="report">点击此处对该用户进行举报</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- 安全中心公告 -->
    <div class="mongolia" v-if="mongolia">
      <div class="xg-phone">
        <div class="top">
          <div class="top-box"></div>
          <p>提高征婚安全意识，谨防金融经济诈骗</p>
        </div>
        <div class="content">
          <p class="title">骗子都这么说</p>
          <p>跟着我买这个彩票,一定赚</p>
          <p>我有内部投资途径，稳赚不赔</p>
          <p>异地交往钱包丢，可以给我转点钱吗</p>
          <p>父母家人患病，借钱看病</p>
          <p>公司开业要送花篮，麻烦送一送</p>
        </div>
        <!-- <div class="content"><a href="/n/safe">查看骗子骗人全过程</a></div> -->
        <div class="content"><a href="/safe">查看骗子骗人全过程</a></div>
        <div class="Btn">
          <el-button
            ref="btn"
            :disabled="disabled"
            :plain="true"
            @click="sendcode"
          >
            {{ btntxt }}
          </el-button>
        </div>
      </div>
    </div>
    <!-- 屏蔽蒙层 -->
    <div class="pbMongolia" v-if="pbMongolia">
      <div class="xg-phone">
        <i class="icon el-icon-close" @click="CommonBtn(1)"></i>
        <div class="top"><p>屏蔽该用户</p></div>
        <div class="line"></div>
        <p class="title">
          将TA屏蔽后，TA不能再对你发起任何互动，<br />
          TA将进入你的黑名单
        </p>
        <div class="Btn">
          <div class="cancel" @click="CommonBtn(1)">取消</div>
          <div class="confirm" @click="queren(1)">确认</div>
        </div>
      </div>
    </div>
    <!-- 举报蒙层 -->
    <div class="jubao-mongolia" v-if="reportMongolia">
      <div class="xg-phone">
        <i class="icon el-icon-close" @click="CommonBtn(2)"></i>
        <div class="top"><p>举报该用户</p></div>
        <div class="line"></div>
        <p class="title-tip">将 {{ nickName }} 举报</p>
        <div class="title">
          <span><i style="color: red"> *</i> 举报类型</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <p class="tip" v-if="a">请选择举报类型</p>
        </div>
        <div class="liyou">
          <span> <i style="color: red"> *</i> 举报理由</span>
          <div class="title-reason">
            <textarea
              id="text"
              v-model="rise"
              @input="riseInput"
              maxlength="500"
              placeholder="请填写举报理由，详细描述你所遇到的情况，以便客服人员快速为你处理"
            />
            <span class="number">{{ extent }}/500</span>
          </div>
          <p class="liyou-tip" v-if="b">请填写举报理由</p>
        </div>
        <div class="jietu">
          <span><i style="color: red"> *</i> 证据截图（最多6张）</span>
          <div class="jietu-a">
            <!-- 举报截图上传 -->
            <el-upload
              action
              :http-request="UpLoad"
              list-type="picture-card"
              :limit="numLL"
              :file-list="fileList"
              :on-success="handleAvatarSuccess2"
              :on-remove="handleRemoveLL"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <p class="jietu-tip" v-if="c">至少上传一张证据截图</p>
        </div>
        <div class="info">
          <span>你的名字</span>
          <el-input v-model="YouName" placeholder="请填写你的姓名"></el-input>
        </div>
        <div class="info">
          <span>你的手机号</span>
          <el-input
            v-model="YouPhone"
            placeholder="请填写真实手机号"
          ></el-input>
        </div>
        <div class="Btn">
          <div class="cancel" @click="CommonBtn(2)">取消</div>
          <div class="confirm" @click="juBaoQueren(2)">确认</div>
        </div>
      </div>
    </div>
    <!-- 未实名蒙层 -->
    <div class="NoRealNameMongolia" v-if="NoRealNameMongolia">
      <div class="xg-phone">
        <i class="icon el-icon-close" @click="CommonBtn(3)"></i>
        <div class="top"><p>人脸认证</p></div>
        <div class="line"></div>
        <p class="title">
          为了保障征婚安全，请先完成实名认证，认证后即可给 <br />
          其他用户发送消息
        </p>
        <div class="Btn">
          <div class="cancel" @click="CommonBtn(3)">取消</div>
          <div class="confirm" @click="renzheng()">前往认证</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/header";
import Footer from "@/components/layout/footer";
import local from "@/api/common/local.js";
import emoji from "@/config/emoji";
import {
  query,
  infofollow,
  followin,
  shield,
  shieldInfo,
  infocur,
} from "@/api/login/login.js";
import { informInfom, subTk } from "@/api/member/member.js";
import { client, getFileNameUUID } from "@/api/common/oss/oss.js";
import { ossL } from "@/api/common/oss/ossL.js";
import { loveLove } from "@/api/my/my.js";
import { ossEmoji } from "@/api/common/oss/ossEmoji.js";

// import ChatEmoji from "@/views/myyezai/chat/chatEmoji.vue";
export default {
  data() {
    return {
      src: require("@/assets/images/message/tou.png"),
      notedata: null, //消息
      // fater: true,
      mongolia: false, //进入聊天提示信息
      num: 5, //倒计时
      disabled: true,
      btntxt: "",
      nickName: "",
      id: "",
      city: "",
      age: "",
      height: "",
      education: "",
      educations: [
        {
          value: "高中及以下",
          label: "0",
        },
        {
          value: "中专",
          label: "1",
        },
        {
          value: "大专",
          label: "2",
        },
        {
          value: "大学本科",
          label: "3",
        },
        {
          value: "硕士",
          label: "4",
        },
        {
          value: "博士",
          label: "5",
        },
      ],
      gztext: "",
      falg: true,
      pbtext: "",
      fl: true,
      pbMongolia: false, //屏蔽蒙层
      reportMongolia: false, //举报蒙层
      NoRealNameMongolia: false, //未实名蒙层
      extent: 0,
      rise: "",

      YouName: "",
      YouPhone: "",
      options: [
        {
          value: "色情相关",
          label: "色情相关",
        },
        {
          value: "头像,虚假资料",
          label: "头像,虚假资料",
        },
        {
          value: "婚托,饭托,酒托等",
          label: "婚托,饭托,酒托等",
        },
        {
          value: "骚扰信息",
          label: "骚扰信息",
        },
        {
          value: "诈骗钱财,虚假中奖信息",
          label: "诈骗钱财,虚假中奖信息",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      value: "",
      a: false,
      b: false,
      c: false,
      chatMessage: [],
      avatar: "",
      userAvatar: "",

      //上传图片
      ImgList: "",
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      numLL: 6,
      //举报图片路径,
      jubaoImg: [],
      // jj: [],
      // JubaoImg: "",
      UserId: "",
      realAuthStatus: "",
      iSParameterError: false,
      iSstatus: false,

      iSmes: true,
      reg: /(微信|weixin|wx|加微|加v|qq|扣扣|加扣|加q|手机|电话|1[0-9]{10}|[a-zA-Z0-9\\\\-\\\\_\\\\@]{6,16}|[0-9]{6,11}|(.*[零O〇一二三四五六七八九①②③④⑤⑥⑦⑧⑨壹贰肆伍陆柒捌玖0-9].*){6,20})+/i,
    };
  },
  components: {
    Header,
    Footer,
    // ChatEmoji,
  },
  created() {
    if (Number(local.get("Date"))) {
      if (
        // Number(local.get("Date")) + 86400000 ==
        // Number(local.get("Date")) + 3600000 <=
        Number(local.get("Date")) + 86400000 <=
        Number(Date.parse(new Date()))
      ) {
        local.set("Date", Date.parse(new Date()));
        this.Btn();
        this.mongolia = true;
      }
    } else {
      this.Btn();
      this.mongolia = true;
      local.set("Date", Date.parse(new Date()));
    }

    this.hun();
    this.gz();
    this.pb();
    this.tkL();
  },
  methods: {
    /**
     * 表情处理
     */
    selectEmoji(v) {
      this.notedata = v;
      this.$refs.txtDom.focus();
    },
    //登录环信账号
    login() {
      const _that = this;
      var options = {
        user: this.UserId, //--(当前用户账号)
        pwd: "yezai_user@easemob",
        appKey: _that.$WebIM.config.appkey,
      };
      _that.$WebIM.conn.open(options);
      _that.$WebIM.conn.listen({
        onOpened: function (message) {
          console.log("00--", message);
          _that.mess();
        },
        onTextMessage: function (message) {
          console.log("onTextMessage", message);
          let a = message.time;
          a = _that.$moment().format("LT");
          message.time = a;
          _that.chatMessage.push(message);
        },
      });
    },
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    //初始化消息
    mess() {
      const _that = this;
      var optionsL = {
        queue: _that.id, //----------------------------------------------------------------------------------------------------(接收消息对象（用户id) )
        isGroup: false,
        count: 4,
        success: function (res) {
          try {
            // let a =  new Date()
            console.log("---", res);
            //获取拉取成功的历史消息
            _that.chatMessage = res;
            if (_that.chatMessage.length <= 0) {
              _that.Btn();
            }
            //处理时间()
            for (let i = 0; i <= _that.chatMessage.length; i++) {
              let a = Date.parse(new Date());
              // console.log(a, parseInt(_that.chatMessage[i].time));
              //一天时间戳为86400000
              if (a - parseInt(_that.chatMessage[i].time) <= 86400000) {
                _that.chatMessage[i].time = _that
                  .$moment(parseInt(_that.chatMessage[i].time))
                  .format("LT");
              } else {
                _that.chatMessage[i].time = _that
                  .$moment(parseInt(_that.chatMessage[i].time))
                  .format("MMMDo");
              }
            }
          } catch (e) {
            // console.log("error", e);
          }
        },
        fail: function () {},
      };
      // _that.WebIM.conn.fetchHistoryMessages(optionsL);
      _that.$WebIM.conn.fetchHistoryMessages(optionsL);
    },
    customEmoji(value) {
      return `<img src="${ossEmoji(value)}" style="width:20px"/>`;
    },
    renderTxt(txt = "") {
      let rnTxt = [];
      let match = null;
      const regex = /(\[.*?\])/g;
      let start = 0;
      let index = 0;
      while ((match = regex.exec(txt))) {
        index = match.index;
        if (index > start) {
          rnTxt.push(txt.substring(start, index));
        }
        if (match[1] in emoji.obj) {
          const v = emoji.obj[match[1]];
          rnTxt.push(this.customEmoji(v));
        } else {
          rnTxt.push(match[1]);
        }
        start = index + match[1].length;
      }
      rnTxt.push(txt.substring(start, txt.length));
      return rnTxt.toString().replace(/,/g, "");
    },
    // 提示跳转安全中心
    an() {
      let rel = this.$router.resolve({
        // path: "/n/safe",
        path: "/safe",
      });
      window.open(rel.href, "_blank");
    },
    //举报字数----------------
    riseInput() {
      let textVal = this.rise.length; //输入的长度
      this.extent = textVal;
    },

    async hun() {
      let Data = local.get("access_token");
      const D = await infocur(Data);
      if (D.code == 0) {
        console.log("00", D);
        this.UserId = D.data.user.id;
        this.login();
        this.userAvatar = ossL(D.data.user.avatar);
        this.realAuthStatus = D.data.user.realAuthStatus; //是否实名认证
        // this.iS
      }
      let a = this.$route.query.objectID;
      this.id = this.$route.query.objectID;
      const { code, data } = await query(a, local.get("access_token"));
      if (code == 0) {
        console.log("信息", data);
        this.nickName = data.user.nickName;
        if (data.user.status != 0) {
          // console.log();
          this.$router.go(-1);
          this.iSParameterError = true;
          // this.$message({
          //   message: "参数错误",
          //   type: "error",
          //   offset: 100,
          // });
        } else {
          this.city = data.user.workAddrCityName;
          this.education = data.user.education;
          this.height = data.user.height;
          this.avatar = ossL(data.user.avatar);
          this.educations.forEach((item) => {
            if (this.education == item.label) {
              this.education = item.value;
            }
          });
          this.age = data.user.age;
          this.iSstatus = true;
        }
      } else {
        this.$router.go(-1);
        this.iSParameterError = true;
        this.$message({
          message: "参数错误",
          type: "error",
          offset: 100,
        });
      }
    },
    Btn() {
      if (this.num > 0) {
        this.num--;
        this.btntxt = `知道了(${this.num}s)`;
        setTimeout(this.Btn, 1000);
      } else {
        this.btntxt = "知道了";
        this.$refs.btn.$el.style.backgroundColor = "#FD686EFF";
        this.disabled = false;
      }
    },
    //按钮
    sendcode() {
      this.mongolia = false;
      local.set("Date", Date.parse(new Date()));
    },
    //发消息
    sends() {
      // if(){}else{

      // }
      if (this.realAuthStatus == 0) {
        this.NoRealNameMongolia = true;
      } else {
        var reg = /^\s*$/g;
        if (this.iSParameterError) {
          this.$message({
            message: "参数错误",
            type: "error",
            offset: 100,
          });
        } else {
          if (
            reg.test(this.notedata) ||
            this.notedata == null ||
            this.notedata == ""
          ) {
            this.$message({
              message: "消息不能为空哦",
              type: "warning",
              offset: 100,
            });
          } else {
            const _that = this;
            console.log("--", this.chatMessage);
            // let id = this.WebIM.conn.getUniqueId(); // 生成本地消息id
            // let msg = new this.WebIM.message("txt", id); // 创建文本消息
            let id = this.$WebIM.conn.getUniqueId(); // 生成本地消息id
            let msg = new this.$WebIM.message("txt", id); // 创建文本消息
            msg.set({
              msg: this.notedata, // 消息内容
              to: _that.id, // -----------------------------------------------------------------------------------------(接收消息对象（用户id) )
              chatType: "singleChat", // 设置为单聊
              success: function (id, serverMsgId) {
                console.log("send private text Success", id, serverMsgId);
                _that.chatMessage.push({
                  data: _that.notedata,
                  time: `${new Date().getHours()}:${
                    new Date().getMinutes() <= 9
                      ? "0" + new Date().getMinutes()
                      : new Date().getMinutes()
                  }`,
                });
                _that.notedata = "";
                let d = {
                  tid: _that.id,
                };
                loveLove(d);
                _that.tkL();
              },

              fail: function (e) {
                if (e.type === "501") {
                  this.$message({
                    message: "消息包含敏感词",
                    type: "warning",
                    offset: 100,
                  });
                }
                // 失败原因:
                // e.type === '603' 被禁言
                // e.type === '605' 群组不存在
                // e.type === '602' 不在群组或聊天室中
                // e.type === '504' 撤回消息时超出撤回时间
                // e.type === '505' 未开通消息撤回
                // e.type === '506' 没有在群组或聊天室白名单
                // e.type === '501' 消息包含敏感词
                // e.type === '502' 被设置的自定义拦截捕获
                // e.type === '503' 未知错误
                console.log("Send private text error", e);
              },
            });
            // this.WebIM.conn.send(msg.body);
            this.$WebIM.conn.send(msg.body);
          }
        }
      }
      // var reg = /^\s*$/g;
      // if (
      //   reg.test(this.notedata) ||
      //   this.notedata == null ||
      //   this.notedata == ""
      // ) {
      //   this.$message({
      //     message: "消息不能为空哦",
      //     type: "warning",
      //     offset: 100,
      //   });
      // } else {
      //   const _that = this;
      //   console.log("--", this.chatMessage);
      //   // let id = this.WebIM.conn.getUniqueId(); // 生成本地消息id
      //   // let msg = new this.WebIM.message("txt", id); // 创建文本消息
      //   let id = this.$WebIM.conn.getUniqueId(); // 生成本地消息id
      //   let msg = new this.$WebIM.message("txt", id); // 创建文本消息
      //   msg.set({
      //     msg: this.notedata, // 消息内容
      //     to: _that.id, // -----------------------------------------------------------------------------------------(接收消息对象（用户id) )
      //     chatType: "singleChat", // 设置为单聊
      //     // ext: {
      //     //   key: "00",
      //     //   key2: {
      //     //     key3: "00",
      //     //   },
      //     // }, //扩展消息
      //     success: function (id, serverMsgId) {
      //       console.log("send private text Success", id, serverMsgId);
      //       _that.chatMessage.push({
      //         data: _that.notedata,
      //         time: `${new Date().getHours()}:${
      //           new Date().getMinutes() <= 9
      //             ? "0" + new Date().getMinutes()
      //             : new Date().getMinutes()
      //         }`,
      //       });
      //       // _that.mess()
      //       _that.notedata = "";
      //     },

      //     fail: function (e) {
      //       if (e.type === "501") {
      //         this.$message({
      //           message: "消息包含敏感词",
      //           type: "warning",
      //           offset: 100,
      //         });
      //       }
      //       // 失败原因:
      //       // e.type === '603' 被禁言
      //       // e.type === '605' 群组不存在
      //       // e.type === '602' 不在群组或聊天室中
      //       // e.type === '504' 撤回消息时超出撤回时间
      //       // e.type === '505' 未开通消息撤回
      //       // e.type === '506' 没有在群组或聊天室白名单
      //       // e.type === '501' 消息包含敏感词
      //       // e.type === '502' 被设置的自定义拦截捕获
      //       // e.type === '503' 未知错误
      //       console.log("Send private text error", e);
      //     },
      //   });
      //   // this.WebIM.conn.send(msg.body);
      //   this.$WebIM.conn.send(msg.body);
      // }
    },
    handleKeyCode(event) {
      // if (event.keyCode == 13) {
      //   if (!event.metaKey) {
      //     event.preventDefault();
      //     this.sends();
      //   } else {
      //     this.notedata = this.notedata + "\n";
      //   }
      // }
      //---
      if (event.keyCode == 13) {
        if (event.ctrlKey && event.keyCode == 13) {
          this.notedata = this.notedata + "\n";
        } else {
          event.preventDefault();
          this.sends();
        }
      }
    },
    //返回
    back() {
      // this.$router.push("/n/message");
      let rel = this.$router.resolve({
        path: "/n/message",
      });
      window.open(rel.href, "_blank");
    },
    // 初始化关注状态
    async gz() {
      let Data = this.$route.query.objectID;
      // let a = local.get("access_token");
      let b = {
        // token: a,
        followMemberId: Data,
      };
      const { code, data } = await infofollow(b);
      if (code == 0) {
        // console.log("信息", data);
        // this.gztext = data;
        if (data.opFlag == "0") {
          this.falg = false;
          this.gztext = "已关注";
        } else {
          this.falg = true;
          this.gztext = "关注";
        }
      }
    },
    //改变状态
    async guanzhu() {
      this.falg = !this.falg;

      let c;
      if (this.falg == true) {
        c = 1;
      } else {
        c = 0;
      }
      let Data = this.$route.query.objectID;
      let d = local.get("access_token");
      let a = {
        token: d,
        followMemberId: Data,
        opFlag: c,
      };
      const { code, msg } = await followin(a);
      if (code == 0) {
        if (c == 1) {
          this.$message({
            message: "取消关注",
            type: "success",
            offset: 100,
          });
          this.gz();
          this.tkL();
        } else {
          this.$message({
            message: "关注成功",
            type: "success",
            offset: 100,
          });
          this.gz();
          this.tkL();
        }
      } else if (code == 1) {
        this.$message.error({
          message: msg,
          // type: "success",
          offset: 100,
        });
      }
    },
    //获取当前屏蔽状态
    async pb() {
      let Data = this.$route.query.objectID;

      let b = {
        shieldMemberId: Data,
      };
      const { code, data } = await shieldInfo(b);
      if (code == 0) {
        if (data.opFlag == "0") {
          this.fl = false;
          this.pbtext = "取消屏蔽";
        } else {
          this.fl = true;
          this.pbtext = "屏蔽";
        }
      }
    },
    //屏蔽弹框01
    pingbi() {
      this.pbMongolia = true;
    },
    //屏蔽弹框02
    report() {
      this.reportMongolia = true;
    },
    //蒙层隐藏
    CommonBtn(num) {
      if (num == 1) {
        this.pbMongolia = false;
      } else if (num == 2) {
        this.reportMongolia = false;
        this.fileList = [];
        this.value = "";
        this.rise = "";
        this.a = false;
        this.b = false;
      } else if (num == 3) {
        this.NoRealNameMongolia = false;
      }
    },
    //改变屏蔽状态
    async queren(id) {
      this.fl = !this.fl;
      let c;
      if (this.fl == true) {
        c = "1";
      } else {
        c = "0";
      }
      if (id == 1) {
        let Data = this.$route.query.objectID;
        // let a = local.get("access_token");
        let b = {
          // token: a,
          opFlag: c,
          shieldMemberId: Data,
        };
        const { code, msg } = await shield(b);

        if (code == 0) {
          // this.$message({
          //   message: msg,
          //   type: "success",
          // });
          const that = this;
          setTimeout(function () {
            that.$message({
              message: msg,
              type: "success",
              offset: 100,
            });
          }, 0);
          this.pb();
        } else if (code == 1) {
          // this.$message.error({
          //   message: msg,
          //   offset: 100,
          // });
          const _that = this;
          setTimeout(function () {
            _that.$message.error({
              message: msg,
              offset: 100,
            });
          }, 0);
        }
        this.tkL();
        // setTimeout(function(){
        this.pbMongolia = false;
        // },0)
      } else if (id == 2) {
        if (this.value == "" || this.rise == "") {
          console.log("你好", this.$route.query.objectID);
        } else {
          console.log("提交");
          let a = {
            cellphone: this.YouPhone,
            informReason: this.rise,
            informType: this.value,
            informedId: this.$route.query.objectID,
            realName: this.YouName,
          };
          const { code, msg } = await informInfom(a);
          this.tkL();
          if (code == 0) {
            this.$message({
              message: msg,
              type: "success",
            });
            this.tkL();
            this.fileList = [];
          } else {
            this.$message({
              message: msg,
              type: "warning",
            });
          }
        }
      }
    },

    renzheng() {
      const _that = this;
      setTimeout(function () {
        let rel = _that.$router.resolve({
          path: "/n/user/_auth",
        });
        window.open(rel.href, "_blank");
      }, 0);
      _that.NoRealNameMongolia = false;
    },

    // 上传截图
    UpLoad(file) {
      const that = this;
      that.tkL();
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;
        client(that.dataObj)
          .multipartUpload(
            process.env.NODE_ENV === "production"
              ? `prod/report/${fileName}`
              : `dev/report/${fileName}`,
            // `/dev/report/${fileName}`
            file.file,
            {
              headers: {
                "Content-Type": "img/jpg",
              },
            }
          )
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.fileList.push({
              name: res.name,
              url: ossL(res.name),
            });
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    //删除数据(图片)
    removeByValue(arr, attr, value) {
      var index = 0;
      for (var i in arr) {
        if (arr[i][attr] == value) {
          index = i;
          break;
        }
      }
      arr.splice(index, 1);
    },
    removeByValueLL(arr, value) {
      arr.forEach((item, i) => {
        if (item == value) {
          arr.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
        }
      });
    },
    async handleRemoveLL(file) {
      //数据处理
      let temp = file.url.lastIndexOf("?");
      let a = file.url.substring(0, temp);
      let tempL = a.lastIndexOf(".com");
      let b = a.substring(tempL + 4, a.length);
      this.removeByValue(this.fileList, "name", b);
      this.removeByValueLL(this.jubaoImg, b);
    },
    handleAvatarSuccess2(file) {
      console.log("成功", file);
    },
    //举报
    async juBaoQueren(id) {
      this.jubaoImg = [];
      if (id == 2) {
        //举报类型
        if (this.value == "") {
          this.a = true;
        } else {
          this.a = false;
        }
        //举报理由
        if (this.rise == "") {
          this.b = true;
        } else {
          this.b = false;
        }
        //截图证据
        if (this.fileList.length == 0) {
          this.c = true;
        } else {
          this.c = false;
        }

        for (let i = 0; i < this.fileList.length; i++) {
          if (this.jubaoImg.indexOf(this.fileList[i]) < 0)
            this.jubaoImg.push(this.fileList[i].name);
        }
        this.ImgList = this.jubaoImg.join(",");
        //  满足条件调用举报接口
        if (this.value != "" && this.rise != "" && this.fileList.length != 0) {
          let a = {
            cellphone: this.YouPhone, //电话
            informReason: this.rise, //理由
            informType: this.value, //类型
            informedId: this.$route.query.objectID, //举报id
            realName: this.YouName, //举报昵称
            imgUrl: this.ImgList, //截图
          };
          const { code, msg } = await informInfom(a);
          // console.log("ss", code);
          if (code == 0) {
            // this.$message({
            //   message: msg,
            //   type: "success",
            // });
            this.reportMongolia = false;
            this.jubaoImg = [];
            this.tkL();
            const that = this;
            setTimeout(function () {
              that.$message({
                message: msg,
                type: "success",
                offset: 100,
              });
            }, 500);
          } else {
            // this.$message({
            //   message: msg,
            //   type: "warning",
            // });
            this.reportMongolia = false;
            this.jubaoImg = [];
            this.tkL();
            const that = this;
            setTimeout(function () {
              that.$message({
                message: msg,
                type: "warning",
                offset: 100,
              });
            }, 500);
          }
        }
      }
    },

    openKnowledge() {
      this.iSmes = false;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 52px;
  background-color: #fafafaff;
  display: flex;
  justify-content: center;
  .wrapper {
    width: 1200px;
    // height: 1400px;
    background-color: #fff;
    box-sizing: border-box;
    .back {
      cursor: pointer;
      height: 40px;
      background-color: #ffe4e5ff;
      display: flex;
      align-items: center;
      color: #fd686eff;
      font-size: 14px;
      i {
        margin-left: 40px;
        cursor: pointer;
      }
    }
    .info {
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 50px;
      .left {
        height: 80px;
        display: flex;
        .header {
          width: 80px;
          height: 80px;
          img {
            width: 80px;
            height: 80px;
          }
          // background-color: antiquewhite;
        }
        .info-i {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p:nth-child(1) {
            font-size: 18px;
            // color: #333333ff;
            color: #333;
            // color: #ADADAD;
          }
          .p_id {
            color: #adadad;
          }
          p {
            font-size: 16px;
            // color: #777777ff;
            color: #666;
          }
        }
      }
      .right {
        height: 80px;
        display: flex;
        align-items: center;

        .Btn1,
        .Btn2,
        .Btn3 {
          margin-left: 12px;
          width: 94px;
          height: 38px;
          background-color: #fd686eff;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
        }
        .Btn1-a {
          margin-left: 12px;
          width: 94px;
          height: 38px;
          background-color: #dbdbdb;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
        }
        .Btn3-a {
          margin-left: 12px;
          width: 94px;
          height: 38px;
          background-color: #dbdbdb;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
        }
        .Btn2,
        .Btn3 {
          background-color: #fff;
          color: #adadadff;
          border: 1px solid #adadad;
          box-sizing: border-box;
        }
        .Btn1:hover,
        .Btn2:hover,
        .Btn3:hover {
          background-color: rgba(253, 104, 110, 0.6);
          color: #ffffff;
          border: none;
        }
      }
    }
    .line {
      height: 1px;
      width: 100%;
      background-color: #ecececff;
    }
    .chat {
      .jiazaiX {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        height: 40px;
        width: 100px;
        border: 1px solid #333;
        border-radius: 4px;
        margin-top: 10px;
        color: #333;
        cursor: pointer;
      }
      //测试
      ul {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 50px;
        .atalk {
          // margin: 10px;
          align-self: flex-start;
          // background-color: khaki;
          .chat-i {
            display: flex;
            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
            }
            .chat-mess {
              display: flex;
              flex-direction: column;
              // justify-content: space-around;
              margin-left: 10px;
              .chat-mess-i-time {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 28px;
              }
              .mes {
                padding: 15px;
                text-align: justify;
                flex: 1;
                // max-width: 1000px;
                word-break: break-all;
                word-wrap: break-word;
                box-sizing: border-box;
                border-radius: 4px;
                background-color: #f4f4f4;
                font-size: 16px;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                img {
                  vertical-align: text-bottom;
                }
              }
              /**
              消息 */
              .mes_private {
                // padding: 15px;
                // padding: 16rpx 30rpx;
                text-align: justify;
                flex: 1;
                // max-width: 500rpx;
                // word-break: break-all;
                word-break: break-all;
                word-wrap: break-word;
                box-sizing: border-box;
                border-radius: 4px;
                // background-color: #87B2FF;
                background-color: #f4f4f4;
                color: #333;
                font-size: 16px;
                .mes_private_no {
                  display: flex;
                  font-size: 16px;
                  border-radius: 4px;
                  .private_no_left {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    font-size: 14px;
                  }

                  .private_no_right {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border-radius: 4px;
                    font-size: 14px;
                    background: linear-gradient(
                      180deg,
                      #c3d8fd 0%,
                      #90b0fb 100%
                    );
                    color: #ffffff;

                    img {
                      width: 29px;
                      width: 20px;
                      height: 33px;
                      height: 24px;
                    }
                  }
                }

                .mes_private_yes {
                  // padding: 16px 30px;
                  // text-align: justify;
                  // flex: 1;
                  // word-break: break-all;
                  // word-wrap: break-word;
                  // box-sizing: border-box;
                  // border-radius: 12px;
                  // background-color: #ffffff;
                  // font-size: 28px;
                  // color: #333;

                  padding: 15px;
                  text-align: justify;
                  flex: 1;
                  // max-width: 1000px;
                  word-break: break-all;
                  word-wrap: break-word;
                  box-sizing: border-box;
                  border-radius: 4px;
                  background-color: #f4f4f4;
                  font-size: 16px;
                }
              }
            }
          }
        }
        .btalk {
          // text-align: right;
          // margin: 10px;
          align-self: flex-end;
          // background-color: lightcoral;
          .chat-ii {
            display: flex;
            position: relative;

            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
            }
            .chat-mess-i {
              display: flex;
              flex-direction: column;
              margin-right: 10px;
              .chat-mess-i-time {
                display: flex;
                align-items: center;

                justify-content: flex-end;
                height: 28px;
              }
              .mes {
                padding: 15px;
                flex: 1;
                text-align: justify;
                // max-width: 1000px;
                word-break: break-all;
                word-wrap: break-word;
                box-sizing: border-box;
                border-radius: 4px;
                background-color: #f4f4f4;
                font-size: 16px;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                img {
                  vertical-align: text-bottom;
                }
              }
            }
          }
        }
        li {
          margin-bottom: 29px;
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }
      }
      //提示
      .chat-Tips {
        display: flex;
        justify-content: center;
        .tips-box {
          width: 796px;
          height: 150px;
          background: #ffe4e5;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          box-sizing: border-box;
          padding: 0px 26px;
          text-align: center;
          line-height: 30px;
          color: #777777ff;
          h3 {
            font-size: 18px;
          }
          .tip-aq {
            display: flex;
            // align-items: center;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: #333;
            font-weight: bold;
            .tip-aq_img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
          }
        }
      }
      .message {
        display: flex;
        justify-content: center;
        margin-top: 90px;
        // .mess,
        textarea {
          padding: 10px;
          width: 1036px;
          height: 276px;
          border-radius: 8px;
          border: 1px solid #ececec;
        }
      }
      .Btn {
        display: flex;
        justify-content: center;
        margin-top: 56px;
        .Btn-box {
          width: 232px;
          height: 40px;
          background: #fd686e;
          border-radius: 19px;
          color: #fff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .safe-tips {
        width: 1036px;
        padding-bottom: 40px;
        margin: 66px auto 0 auto;
        line-height: 20px;
        color: #666;
        // visibility: initial;
        .safe-tips-iii {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            vertical-align: sub;
          }
          .title {
            line-height: 22px;
            font-size: 16px;
          }
        }
      }
    }
    .chat-error {
      .info-b {
        width: 100%;
        height: 270px;
        background-color: #ffffff;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .big-img {
          position: absolute;
          top: -100px;
          left: 60px;
          width: 220px;
          height: 220px;
        }
        p {
          margin-top: 10px;
          width: 570px;
          height: 48px;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #333333;
          line-height: 24px;
          span {
            color: #549cebff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
//进入聊天页面
.mongolia {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  .xg-phone {
    z-index: 889;
    // padding: 20px;
    box-sizing: border-box;
    background-color: #ffffffff;
    position: fixed;
    top: 134px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 8px;
    width: 440px;
    height: 480px;
    background: #ffffff;
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url("../../../assets/images/Mantle/menc1.png") no-repeat;
      color: #ffffff;
      font-size: 18px;
      height: 176px;
      padding-top: 24px;
      box-sizing: border-box;
      p {
        text-align: center;
      }
      .top-box {
        width: 62px;
        height: 78px;
        background: url("../../../assets/images/Mantle/anquan.png") no-repeat;
        margin-bottom: 16px;
      }
    }
    .content {
      display: flex;
      //   justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      a {
        margin-top: 24px;
        color: #5797ffff;
        text-decoration: underline;
      }
      .title {
        text-align: center;
        padding-top: 36px;
        box-sizing: border-box;
        color: #333333ff;
        // width: 256px;
        // width: 96px;
        // height: 22px;
        font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-bottom: 20px;
        // line-height: 22px;
      }
      p {
        font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #777777;
        line-height: 22px;
      }
    }

    .Btn {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      /deep/ .el-button {
        width: 140px;
        height: 40px;
        background: #e3e3e3;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
      }
      /deep/ .el-button.is-plain:focus,
      .el-button.is-plain:hover {
        border-color: #e3e3e3;
      }
      //   /deep/ .el-button:focus, .el-button:hover{
      //       border-color: none;
      //   }
    }
  }
}
//举报蒙层
.jubao-mongolia {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  .xg-phone {
    z-index: 889;
    box-sizing: border-box;
    background-color: #ffffffff;
    position: fixed;
    top: 100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 8px;
    width: 500px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 14px;
    span {
      font-size: 14px;
    }
    .top {
      font-size: 14px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-weight: bold;
      color: #333333ff;
      p {
        text-align: center;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #ecececff;
    }
    .title {
      margin-top: 18px;
      align-items: center;
      box-sizing: border-box;
      color: #333333ff;
      font-size: 14px;
      justify-content: space-between;
      display: flex;
      position: relative;
      /deep/ .el-select {
        width: 320px;
      }
      /deep/ .el-input__inner::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #adadad;
      }
      .tip {
        position: absolute;
        font-size: 12px;
        top: 42px;
        left: 80px;
        color: red;
      }
    }
    .title-tip {
      margin-top: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      color: #333333ff;
    }
    .liyou {
      margin-top: 18px;
      box-sizing: border-box;
      color: #333333ff;
      display: flex;
      justify-content: space-between;
      position: relative;
      .title-reason {
        position: relative;
        #text {
          padding: 12px 20px;
          width: 320px;
          height: 150px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 2px;
          /*去掉右下角的斜杠，不允许文本框缩放*/
          resize: none;
          /*将边框去掉*/
          border: none;
          border-radius: 2px;
          /*设置字体大小*/
          font-size: 14px;
          /* placeholder位置 提示靠左靠右居中等 */
          text-align: left;
          /* 其他样式还有不少，没有用就没查，需要可自行查看 */
          position: relative;
        }
        .number {
          position: absolute;
          color: #adadadff;
          bottom: 7px;
          right: 16px;
        }
        textarea::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #adadad;
        }
        textarea:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #adadad;
        }
        textarea::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #adadad;
        }
        textarea::-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #adadad;
        }
      }
      .liyou-tip {
        position: absolute;
        font-size: 12px;
        bottom: -14px;
        left: 80px;
        color: red;
      }
    }
    .jietu {
      margin-top: 18px;
      // justify-content: center;
      box-sizing: border-box;
      color: #333333ff;
      // width: 316px;
      font-size: 14px;
      color: #333333;
      display: flex;
      flex-direction: column;
      position: relative;
      span {
        margin-right: 10px;
      }
      .jietu-a {
        margin-top: 10px;
      }
      /deep/ .el-upload,
      .el-upload-list {
        width: 68px;
        height: 68px;
        line-height: 68px;
      }
      /deep/ .el-upload-list {
        li {
          width: 68px;
          height: 68px;
        }
      }
      .jietu-tip {
        position: absolute;
        font-size: 12px;
        bottom: -15px;
        left: 0px;
        color: red;
      }
    }
    .info {
      margin-top: 18px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      span {
        color: #333333ff;
        margin-right: 10px;
      }
      /deep/ .el-input {
        width: 320px;
        height: 40px;
      }
    }
    .Btn {
      display: flex;
      justify-content: center;
      margin-top: 54px;
      margin-bottom: 40px;
      .cancel,
      .confirm {
        margin-left: 20px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 40px;
        border: 1px solid;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #adadad;
      }
      .confirm {
        border: none;
        background: #fd686e;
        color: #ffffff;
      }
    }
    .icon {
      cursor: pointer;
      display: flex;
      color: #adadadff;
      font-size: 18px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
//屏蔽蒙层
.pbMongolia {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  .xg-phone {
    z-index: 889;
    box-sizing: border-box;
    background-color: #ffffffff;
    position: fixed;
    top: 256px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 8px;
    width: 440px;
    height: 214px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      // font-size: 18px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-weight: bold;
      width: 100%;
      background: #f5f5f5;
      color: #666;
      font-size: 14px;
      border-radius: 4px 4px 0px 0px;
      p {
        text-align: center;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #ecececff;
    }
    .title {
      text-align: center;
      // padding-top: 36px;
      padding-top: 30px;
      box-sizing: border-box;
      color: #333333ff;
      width: 316px;
      height: 44px;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
    }
    .Btn {
      display: flex;
      justify-content: center;
      margin-top: 54px;
      .cancel,
      .confirm {
        margin-left: 20px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 40px;
        border: 1px solid;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #adadad;
      }
      .confirm {
        border: none;
        background: #fd686e;
        color: #ffffff;
      }
    }
    .icon {
      cursor: pointer;
      display: flex;
      color: #adadadff;
      font-size: 18px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}

//未实名 NoRealNameMongolia
.NoRealNameMongolia {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  .xg-phone {
    z-index: 889;
    box-sizing: border-box;
    background-color: #ffffffff;
    position: fixed;
    top: 256px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 8px;
    width: 440px;
    height: 214px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      // font-size: 18px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-weight: bold;
      width: 100%;
      background: #f5f5f5;
      color: #666;
      font-size: 14px;
      border-radius: 4px 4px 0px 0px;
      p {
        text-align: center;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #ecececff;
    }
    .title {
      text-align: center;
      // padding-top: 36px;
      padding-top: 30px;
      box-sizing: border-box;
      color: #333333ff;
      // width: 316px;
      height: 44px;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
    }
    .Btn {
      display: flex;
      justify-content: center;
      margin-top: 54px;
      .cancel,
      .confirm {
        margin-left: 20px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 40px;
        border: 1px solid;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #adadad;
      }
      .confirm {
        border: none;
        background: #fd686e;
        color: #ffffff;
      }
    }
    .icon {
      cursor: pointer;
      display: flex;
      color: #adadadff;
      font-size: 18px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}

/deep/ .el-upload--picture-card {
  line-height: 74px !important;
}
</style>

